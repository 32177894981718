// js-image-zoom
let options = {
  zoomWidth:500,
  scale: 1,
  zoomPosition: "right",
  fillContainer: true,
  offset: { vertical: 0, horizontal: 10}
};

let galleries = document.querySelectorAll('.gallery');

class imageGallery {
  container;
  featuredImage;
  imageList = [];
  index = 0;

  constructor(container) {
    this.container = container;
    this.featuredImage = this.container.querySelector('.gallery-zoom');

    // Set the image zoom and initiate
    options.img = this.featuredImage.dataset.first;
    this.imageZoom =  new ImageZoom(this.featuredImage, options);

    // Enable the click functions for image thumbnails
    this.container.querySelectorAll('.gallery__image').forEach((element, i) => {
      this.imageList.push(element);
      element.addEventListener('click', this.goTo.bind(this, element, i), false)
    });

    // Assign arrow control
    this.container.querySelector('.gallery__prev').addEventListener('click', this.decrease.bind(this));
    this.container.querySelector('.gallery__next').addEventListener('click', this.increase.bind(this));
  }

  // Go directly to an image.
  goTo(thumb, index = false) {
    if(index) this.index = index; // Update the index if the action came from a thumbnail

    this.featuredImage.innerHTML = '';

    if(thumb.dataset.type == 'embed') {
      let i = (index) ? index : this.index;

      let code = embeds.filter(item => item.index == i)[0].code;
      this.featuredImage.innerHTML = code;
    } else {
      this.featuredImage.dataset.first = thumb.dataset.full; // Set the new featured image
      // this.imageZoom.kill(); // Stop the zoom
      options.img = this.featuredImage.dataset.first; // Set the option correctly.
      this.imageZoom =  new ImageZoom(this.featuredImage, options); // Restart the zoom
    }
  }

  decrease() {
    if(this.index <= 0) { this.index = this.imageList.length - 1 } else { this.index--; }
    this.goTo(this.imageList[this.index]);
  }

  increase() {
    if (this.index >= this.imageList.length - 1) { this.index = 0 } else { this.index++; }
    this.goTo(this.imageList[this.index]);
  }
}

window.onload = () => {
  if(typeof embeds !== undefined) {
    galleries.forEach((gallery) => {
      new imageGallery(gallery);
    })
  }
}